import { openPage } from '../lib/open-page';
export function useOnSubmitCallback(results, props, onChange) {
    return function (evt) {
        evt.preventDefault();
        var _a = results[props.index].url.split('#'), pathname = _a[0], hash = _a[1];
        openPage(pathname, hash);
        props.onClose();
        onChange('');
    };
}
