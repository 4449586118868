import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import DialogContent from './dialog-content';
import { registerWindowSearchFunction } from '../lib/registerWindowSearchFunction';
import { useOpenShortcutListener } from '../hooks/use-open-shortcut-listener';
import { useDialogState } from '../hooks/use-dialog-state';
var defaultSearch = function () { return []; };
function getDialog(onClose, props, index, setIndex) {
    var _a;
    return (h("div", { class: "search-dialog shadowed" },
        h(DialogContent, { onClose: onClose, searchFn: (_a = window[props.search]) !== null && _a !== void 0 ? _a : defaultSearch, index: index, up: function () { return setIndex(index - 1); }, down: function () { return setIndex(index + 1); }, reset: function () { return setIndex(0); } })));
}
var SearchPopup = function (props) {
    var _a = useState(0), index = _a[0], setIndex = _a[1];
    var _b = useDialogState(), isOpen = _b.isOpen, onOpen = _b.onOpen, onClose = _b.onClose;
    registerWindowSearchFunction(props, setIndex, onOpen);
    useOpenShortcutListener(setIndex, onOpen, onClose);
    return (h("div", { id: "search-wrapper" }, isOpen && (h(Fragment, null,
        h("div", { class: "backdrop", onClick: onClose }, "\u00A0"),
        getDialog(onClose, props, index, setIndex)))));
};
export default SearchPopup;
