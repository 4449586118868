import { useLayoutEffect } from 'preact/hooks';
export function useOpenShortcutListener(setIndex, onOpen, onClose) {
    useLayoutEffect(function () {
        document.onkeydown = function (evt) {
            if (evt.ctrlKey && evt.key === 'k') {
                evt.preventDefault();
                setIndex(0);
                onOpen();
            }
            else if (evt.key === 'Escape') {
                evt.preventDefault();
                onClose();
            }
        };
    }, []);
}
