import { h } from 'preact';
export function Preface() {
    return (h("div", null,
        h("h2", null, "Search"),
        h("p", null,
            "Press ",
            h("kbd", null, "ESC"),
            " to exit, ",
            h("kbd", null, "\u2193"),
            ", ",
            h("kbd", null, "\u2191"),
            " to navigate, and ",
            h("kbd", null, "Return"),
            " accept.")));
}
