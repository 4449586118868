import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
export function SearchInput(props) {
    var ref = useRef(null);
    useEffect(function () {
        if (ref.current)
            ref.current.focus();
    }, [ref]);
    return (h("input", { id: "search-input", autoComplete: "off", type: "search", autoFocus: true, value: props.value, onInput: props.onInput, onKeyDown: props.onKeyDown, ref: ref }));
}
