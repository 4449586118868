import { useCallback } from 'preact/hooks';
export function useOnChangeCallback(setQuery, setResults, props, listRef) {
    return useCallback(function (value) {
        var _a;
        setQuery(value);
        setResults(props.searchFn(value));
        props.reset();
        (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ behavior: 'smooth', top: 0 });
    }, [setQuery, props.reset]);
}
