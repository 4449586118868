import { h } from 'preact';
import { useLayoutEffect, useRef } from 'preact/hooks';
export default function SearchResult(_a) {
    var active = _a.active, result = _a.result;
    var ref = useRef(null);
    useLayoutEffect(function () {
        if (ref.current && active) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [active, ref]);
    return (h("li", { key: result.url, "aria-type": 'options', "aria-selected": active, className: active ? 'active' : '' },
        h("a", { href: result.url, ref: ref },
            h("p", null,
                h("small", null, result.url)),
            h("h5", null, result.name),
            h("p", null, result.text))));
}
