import { h } from 'preact';
import { useRef, useState } from 'preact/hooks';
import SearchResult from './search-result';
import { useOnChangeCallback } from '../hooks/use-on-change-callback';
import { Preface } from './preface';
import { useOnSubmitCallback } from '../hooks/use-on-submit-callback';
import { Form } from './form';
export default function DialogContent(props) {
    var _a = useState([]), results = _a[0], setResults = _a[1];
    var _b = useState(''), query = _b[0], setQuery = _b[1];
    var listRef = useRef(null);
    var onChange = useOnChangeCallback(setQuery, setResults, props, listRef);
    var onSubmit = useOnSubmitCallback(results, props, onChange);
    return (h("div", null,
        h(Preface, null),
        getForm({
            onSubmit: onSubmit,
            query: query,
            onChange: onChange,
            props: props,
            results: results,
        }),
        getResultList(listRef, results, props)));
}
function getForm(_a) {
    var onSubmit = _a.onSubmit, query = _a.query, onChange = _a.onChange, props = _a.props, results = _a.results;
    return (h(Form, { onSubmit: onSubmit, query: query, onChange: onChange, index: props.index, up: props.up, down: props.down, resultsLength: results.length }));
}
function getResultList(listRef, results, props) {
    return (h("ul", { ref: listRef }, results.map(function (result, index) { return (h(SearchResult, { key: result.url, active: index === props.index, result: result })); })));
}
