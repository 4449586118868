export function registerWindowSearchFunction(props, setIndex, onOpen) {
    if (window[props.search] !== undefined)
        window[props.search]();
    else {
        console.error('Search function passed to <search-popup> is undefined!');
    }
    window.openSearch = function () {
        setIndex(0);
        onOpen();
    };
}
